/** Inter Font */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Inter/Inter-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Inter/Inter-Medium.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/Inter/Inter-SemiBold.woff') format('woff');
}

/** Color pallete */
$gray: #6c757d;

/** loader animation */
.loader-root {
  text-align: center;
}

.loader {
  align-items: center;
  display: flex;
  font-size: 28px;
  height: 100vh;
  padding-left: 20px;
  position: relative;
}

.loader:before {
  animation: loading 2s linear infinite;
  content: attr(data-text);
  left: 20;
  max-width: 180px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}

.loader-light {
  color: rgba(0, 0, 0, .3);
}

.loader-light:before {
  color: rgba(0, 0, 0, .5);
}

.loader-dark {
  background-color: rgba(0, 0, 0, .8);
  color: rgba(255, 255, 255, .3);
}

.loader-dark:before {
  color: rgba(255, 255, 255, .5);
}

/** payBy select animation*/
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation: tada 1s ease-in-out;
}

.circle-animation {
  animation: scaleIn 2s infinite cubic-bezier(.36, .11, .89, .32);
  background-color: rgb(0, 191, 255);
  border-radius: 50%;
  height: 40px;
  opacity: 0;
  position: absolute;
  right: 40px;
  top: 32px;
  width: 40px;
}

@keyframes scaleIn {
  from {
    opacity: .5;
    transform: scale(1, 1);
  }

  to {
    opacity: 0;
    transform: scale(3, 3);
  }
}

/** Component styles */
body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  float: none !important;
  font-family: Inter;
  margin: 0 auto !important;
  max-height: 100vh;
  min-height: 100vh;
  padding: 0 !important;
}

.pageStyle {
  height: 100%;
  position: relative;
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/** react slick dots */
.slick-dots li {
  margin: 0 !important;
}

.slick-dots {
  bottom: 2px !important;
}

.transitionOnScroll {
  transition: 2000ms background-color ease-in-out;
}

/** react-toastify */
.Toastify__toast-body {
  color: '#FFFFFF';
  font-size: 12px;
}

.Toastify__toast {
  align-items: center;
  border-radius: 6px !important;
  margin: 12px !important;
  max-width: 300px !important;
  min-height: 50px !important;
  padding: 12px !important;
}

.Toastify__close-button {
  padding: 6px !important;
}

//seperator
.separator {
  align-items: center;
  display: flex;
  font-size: 12;
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: center;
}

.separator::before, .separator::after {
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  content: '';
  flex: 1;
}

.separator::before {
  margin-right: 8px;
}

.separator::after {
  margin-left: 8px;
}

/** material ui lowercase button */
.lowercase-button {
  span {
    text-transform: none;
  }
}

.red-button {
  span {
    color: #f53b57;
  }
}


.review-slide-root {
  margin: inherit;
  margin-bottom: 0;
  padding: 20px;
  position: relative;
  width: unset !important;
}

blockquote::before {
  color: #f7ad00;
  content: '\201C';
  display: inline;
  font-family: sans-serif;
  font-size: 30rem;
  left: -1rem;
  line-height: 1em;
  position: absolute;
  top: -3rem;
  z-index: 1;
}

blockquote p {
  color: #fff;
  font-size: 1.6rem;
  position: relative;
  z-index: 10;
}

.base-timer {
  height: 200px;
  margin: auto;
  position: relative;
  width: 200px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke: #808080;
  stroke-width: 7px;
}

.base-timer__path-remaining {
  fill-rule: nonzero;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-width: 7px;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: #ffa500;
}

.base-timer__path-remaining.red {
  color: #ff0000;
}

.base-timer__label {
  align-items: center;
  display: flex;
  font-size: 48px;
  height: 200px;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 200px;
}
